/* Base styles for the blog list container */
/* Container styles with a light greyish background */
.cms-blog-container {
    color: #000; /* Black text */
    background-color: #f2f2f2; /* Light grey background */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: Adds subtle shadow for depth */
  }
  
  /* Adjusting list and list item styles for full-width clickable areas */
  .blog-list {
    list-style: none;
    padding-left: 0;
  }
  
  .blog-list-link {
    display: block; /* Make link fill the entire li */
    text-decoration: none; /* Removes underline from links */
    color: #000; /* Black text for links */
  }
  
  .blog-list-item {
    background-color: #fff; /* White background for items */
    margin-bottom: 10px;
    padding: 10px 15px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
  
  .blog-list-item:hover {
    transform: scale(1.02); /* Slight scale effect on hover */
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Deeper shadow on hover for a "lifting" effect */
  }
  
  .blog-list-link {
    display: block; /* Make link fill the entire li */
    text-decoration: none; /* Removes underline from links */
    color: #000; /* Black text for links */
    transition: color 0.3s; /* Smooth transition for color change */
  }
  
  .blog-list-link:hover, .blog-list-link:focus {
    color: #666; /* Dark grey color on hover/focus */
    text-decoration: none; /* Ensures underline does not reappear on hover/focus */
  }
  
  /* Enhancements for loading and error states */
  .blog-list-loading {
    color: #000;
  }
  
  .blog-list-error {
    color: #D8000C; /* Red color for error messages */
  }
  