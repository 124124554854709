.popup {
    position: fixed;
    right: -100%; /* Start off-screen */
    top: 60px;
    width: 300px; /* Adjust size as needed */
    height: auto;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: right 0.5s ease-out; /* Smooth transition for sliding in */
    z-index: 1000;
    overflow: hidden;
    border-radius: 10px;
}

.popup.visible {
    right: 20px; /* Slide in to visible area */
}
.popup.closing {
    right: -350px; /* Slide out */
    /* Adjust this property to start the animation immediately */
}

.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #dcab70;
    outline: none;
}
.close-button, .close-button:hover, .close-button:focus, .close-button:active {
    border: none;
    outline: none;
}

.popup-content{
    padding: 20px;
    margin-right: 20px;
    line-height: 1;
}

.pop-up-btn .btn{
    width: 100%;
}

