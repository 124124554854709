.ponudba-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px; /* Set fixed width for each card */
  margin: 0 10px 40px 0; /* Adjust margin as needed */
  position: relative;
  background-color: transparent;
}

.ponudba-card .card-body:hover{
box-shadow: 1px 2px 10px 0 #B5894A;
}

  
  .ponudba-card .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; 
    margin-top: auto;
    background-color: transparent;
    border-top: none;
    padding: 5px;
  }
  
  .price {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .ponudba-card .card-body{
    height: 100%;
    box-shadow: 1px 2px 4px 0 rgb(220,171,112,0.63);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }

  .cardtitle{
    margin-bottom: 15%;
    height: 50px;
  }

  .cardsubtitle{
    margin-bottom: 300px;
  }

  .vsebina{
    text-align: left;
    padding-left: 0;
  }

  .linknamen{
    font-style: italic;
    color: #B5894A;
    text-decoration: underline;
    cursor: pointer;
  }
 
  