.card-list {
    display: flex;
    flex-wrap: wrap; /* Prevent wrapping onto the next row */
    justify-content: space-evenly; /* Adjust as needed */
    overflow-x: auto; /* Add horizontal scrollbar if cards overflow */
  }

  .card-img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    display: block;
    margin: auto;
    border: 2px solid #DCAB70;
    padding: 10px;
    border-radius: 1000px;
  }

  
  .card {
    text-align: center;
    border: none; /* Remove border */
    margin: 10px;
    position: relative;
    width: 320px;
  }
  .dejav .card{
    border: 1px solid rgba(220, 171, 112, 0.2);
    box-shadow: 0 4px 6px rgba(220, 171, 112, 0.2);
    padding-top: 20px;
    border-radius: 20px;
  }

  /*.card-img-overlay{
    position: absolute;
    top: 60px;
    color: #fff;
  }*/
  
  .image-container {
    position: relative;
    width: 200px; /* Match the image width */
    height: 200px; /* Match the image height */
    margin: auto; /* Center the container */
  }
  
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid #DCAB70;
    padding: 10px;
  }
  
  .image-overlay {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    background-color: rgba(0, 0, 0, 0); /* Transparent initially */
    transition: background-color 0.3s ease; /* Smooth transition for the overlay */
    pointer-events: none; /* Allows clicking through the overlay */
    border-radius: 1000px;
  }

  .overlay-text{
    position: absolute;
    top: 70px;
    left: 35px;
    color: #fff;
    opacity: 0; /* Make text invisible by default */
    transition: opacity 0.5s ease; /* Smooth transition for the opacity */
    pointer-events: none; 
  }
  
  .card:hover .overlay-text {
    opacity: 1; /* Make text visible on card hover */
  }

  .card:hover .image-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Blackish overlay on hover */
  }
