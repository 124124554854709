.hipno .head-subtitle{
    background-color: #FBF5EE;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.hipno .head-contact{
    text-align: center;

    height: 100%;
}

.hipno .head-contact h5{
    font-family: "Dancing Script", cursive;
    margin-bottom: 20px;
}

/* Add this in your CSS file */
.hipno .tabs-container {
    background-color: #FBF5EE; /* Replace with the color your designer provided */
    position: relative;
  }

  .hipno .nav-item {
    margin-right: 2px; /* Adjust the space between tabs */
  }

  .hipno .nav-item button{
    width: 100%;
    height: 100%;
  }

  
  .hipno .nav-link {
    border: 1px solid transparent; /* Hides the border */
    border-radius: 0; /* Tabs with sharp corners */
    background-color: #FBF5EE; /* Non-active tab background color */
    color: #000; /* Non-active tab text color */
    padding: 0.5rem;
  }
  
  .hipno .nav-link:hover {
    background-color: #d8d8d8; /* Tab background color on hover */
  }
  
  .hipno .nav-tabs .nav-link.active {
    background-color: #fefcfa; /* Active tab background color */
    color: #000; /* Active tab text color */
    border-bottom: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  /* Override the focus style for the tabs */
  .hipno .nav-link:focus {
    outline: none; /* Removes the outline */
    box-shadow: none; /* Removes any box-shadow if present */
    background-color: #fefcfa; /* Active tab background color */
    color: #000; /* Active tab text color */
    border-bottom: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  
  /* Optionally, add a custom style for focused tabs for accessibility */

  
  .hipno .tab-content {
    background-color: #fefcfa; /* Content area background color */
    padding: 15px;


  }
  
  @media (max-width: 768px) {
    /* Responsive design adjustments */
    .hipno .nav-link {
      padding: 8px 10px; /* Smaller padding on smaller screens */
    }
    .hipno .nav-tabs .nav-link.active {
        background-color: #fefcfa; /* Active tab background color */
        color: #000; /* Active tab text color */
        border-bottom: none;

      }

  }




.hipnoslika{
    position: relative;
}

.hipnoslika img{
    width: 100%;
    padding: 40px 20px;
    position: relative;
    z-index: 2;
}

.spots1{
    position: absolute;
    right: -14px;
    top: 5px;
    z-index: 1;
}

.spots2{
    position: absolute;
    left: -35px;
    top: -35px;
    z-index: 1;
}

.spots3{
    position: absolute;
    right: -35px;
    bottom: -35px;
    z-index: 1;
}

.zin{
    position: relative;
    z-index: 4;
}

.ozadje{
    margin-top: 50px;
    position: relative;
    background-color: #DCAB70;
    border-radius: 20px;
    padding: 20px 30px;
    z-index: 500;
}

/*SAMOHIPNOZA*/

.fontsamh{
    font-size: 2.5rem;
}

.samohipnoza img{
    width: 100%;
    height: auto;
    padding: 20px;
}
.fonts{
    font-size: 1.3rem;
}
.spots4{
    position: absolute;
    right: -35px;
    top: -35px;
    z-index: -1;
}
.spots5{
    position: absolute;
    left: -35px;
    bottom: -35px;
    z-index: -11;
}

.blokadeslika{
    width: 40%;
    height: auto;    
    float: right;
    margin-top: 5%;
    padding: 10px ;
}
.blokadeslika img{
    width: 100%;
    height: auto;
    border-radius: 20px;
}

  
  li::marker {
    color: #DCAB70; /* The color of the bullet */
  }

/*pretirana skrb*/
.skrbslike {
    position: relative;
    width: 70%;
    height: 700px; /* This might need to be adjusted based on the actual height of the images */
    margin: auto; /* This will center the div if it's less than 100% of its container width */
    margin-bottom: 50px;
  }
  
  .skrbslike img {
    position: absolute;
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .slikalevo {
    top: 10%;
    left: 0;
    width: 53%;
    z-index: 1; /* This image will be under the right image if they overlap */
  }
  
  .slikadesno {
    top: 0;
    right: 0;
    width: 53%;
    z-index: 0; /* This image will be on top of the left image */
  }
  
  .slikaspodaj {
    top: 60%;
    left: 15%; /* This positions the bottom image slightly to the right */
    width: 53%;
    z-index: 1; /* This ensures that the bottom image overlaps the other two if they intersect */
  }


  /*kap*/
  .sredina{
    text-align: center;
  }
  .topbg{
    position: relative;
  }
  .kapslikaozadjedesno{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    opacity: 0.3; 
    z-index: 0;
  }
  .kapslikaozadjelevo{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    opacity: 0.3; 
    z-index: 0;
  }
  /*odpuscanje*/
  .slikaodpdiv{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
  }

  .slikaodp {
    width: 100%;
    border-radius: 20px;
    position: relative; /* Needed to position the pseudo-element correctly */
 /* Keeps the pseudo-element within the border-radius */
  }
  
  .slikaodpdiv::after {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(220, 171, 112, 0.25); /* Semi-transparent orange */
    border-radius: 20px; /* To ensure the overlay respects the border-radius of the image */
  }

  .frontspot1{
    position: absolute;
    top:-35px;
    left:-35px;
    z-index: 2;
  }
  .frontspot2{
    position: absolute;
    bottom:-35px;
    right:-35px;
    z-index: 2;
  }

  /*alkoholizem*/
  .alkoslika{
    position: relative;
    width: 100%;
    margin-bottom: 10%;
  }
  .alkofull{
    position: relative;
    width: 100%;
    z-index: 2;
  }
  .backshadow{
    width: 70%;
    background-color: transparent;
    filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 1));
    position: absolute;
    top: 10%;
    left: 23%;
}

.addsp{
    z-index: 1;
}

  /* For large desktops: */
  @media (min-width: 1500px) {
    .skrbslike {
      height: 800px; /* Adjust as needed for large desktops */
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1499px) {
    .skrbslike {
      height: 600px; /* Adjust as needed for large desktops */
    }
  }


  /* For desktops: */
  @media (min-width: 992px) and (max-width: 1199px) {
    .skrbslike {
      height: 500px; /* Adjust as needed for desktops */
    }
  }

  
  /* For tablets: */
  @media (min-width: 768px) and (max-width: 991px) {
    .skrbslike {
      height: 400px; /* Adjust as needed for tablets */
    }
    .blokadeslika img {
      width: 100%; /* Slika na polno širino */
      height: auto; /* Ohrani razmerje višine in širine */
    }
  
    .blokadeslika {
      width: 100%; /* Kontejner slike na polno širino */
      float: none; /* Odstranimo lebdenje */
      padding: 0; /* Odstranimo polnilo */
      margin: 20px 0; /* Prilagodimo prostor na vrhu, če je potrebno */
    }

    .content-blok {
      display: flex;
      flex-direction: column;
  }

  .content-blok .blokadeslika {
      order: 2; /* premakne sliko pod seznam */
  }

  .content-blok ul {
      order: 1;
  }

  .content-blok h4, .content-blok ul, .content-blok .blokadeslika {
      width: 100%; /* Poskrbi, da zavzamejo polno širino */
  }
  .kapslikaozadjedesno{
    width: 60%;
  }
  .kapslikaozadjelevo{
    width: 60%;
  }
  }
  
  /* For phones: */
  @media (max-width: 767px) {
    .skrbslike {
      width: 90%; /* You might want to adjust the width as well */
      height: 350px; /* Adjust as needed for phones */
    }
    .blokadeslika img {
      width: 100%; /* Slika na polno širino */
      height: auto; /* Ohrani razmerje višine in širine */
    }
  
    .blokadeslika {
      width: 100%; /* Kontejner slike na polno širino */
      float: none; /* Odstranimo lebdenje */
      padding: 0; /* Odstranimo polnilo */
      margin: 20px 0; /* Prilagodimo prostor na vrhu, če je potrebno */
    }

    .content-blok {
      display: flex;
      flex-direction: column;
  }

  .content-blok .blokadeslika {
      order: 2; /* premakne sliko pod seznam */
  }

  .content-blok ul {
      order: 1;
  }

  .content-blok h4, .content-blok ul, .content-blok .blokadeslika {
      width: 100%; /* Poskrbi, da zavzamejo polno širino */
  }
  .kapslikaozadjedesno{
    width: 70%;
  }
  .kapslikaozadjelevo{
    width: 70%;
  }
  .frontspot1{
    display: none;
  }
  .frontspot2{
display: none;
  }
  }
  
  
  /* For smaller phones: */
  @media (max-width: 480px) {
    .skrbslike {
      width: 100%; /* On smaller phones, you might want to use the full width */
      height: 250px; /* Adjust as needed for smaller phones */
    }
    .kapslikaozadjedesno{
      width: 80%;
    }
    .kapslikaozadjelevo{
      width: 80%;
    }
  }