.head-title{
  position: relative;
    text-align: center;
    font-size: 3rem;
    font-family: "Dancing Script", cursive;
    margin-bottom: 3%;
    background-color: #dcab70;
    border-radius: 20px;  
    z-index: 3 !important;
}
.menititle{
  position: relative;
  font-size: 4rem;
  text-shadow: 1px 1px 2px #fff;

}
.ime{
  font-size: 3rem;
}

.pictures{
  position: relative;
  width: 100%;
}

.prviprofil{
  width: 25%;
  height: auto;
  float: right;
  margin-top: 120px; /* Push the image down */
  margin-right: -50px;
  margin-left: 25px;
  margin-bottom: 20px;
}

.drugiprofil{
  width: 25%;
  height: auto;
  float: right;
}

  /* Ensure the BrownField component spans the full width */
  .brown-field-container {
    width: 100%;
  }
  .text-with-pictures {
    display: flex;
    align-items: flex-start; /* This will align items to the top */
    flex-wrap: wrap; /* This will allow items to wrap on smaller screens */
    margin-top: 30px;
  }
  
  .lowerpictures, .lowertext {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  

  .omeni3{
    width:90%;
    height: auto;
    text-align: center;
  }

  .fontsize{
    font-size: 2rem;
  }

  .botsection{
    position: relative;
  }

  .backbotgrid{
    position: absolute;
    right: -38px;
    bottom: -25px;
    z-index: -10;
  }


  
/* Clear the float for following content */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}


@media (min-width: 992px) and (max-width: 1199px) {

  .menititle{
    font-size: 3.7rem;
  }
}


/* For tablets: */
@media (min-width: 768px) and (max-width: 991px) {

  .menititle{
    font-size: 3.2rem;
  }

}

/* For phones: */
@media (max-width: 767px) {
  .menititle{
    font-size: 3rem;
  }
  .ime{
    font-size: 2.5rem;
  }
  .prviprofil{
    display: none;
  }
  
  .drugiprofil{
    width: 40%;
    height: auto;
    float: right;
    border-radius: 20px;
    padding: 5px;
  }
  .lowerpictures, .lowertext {
    width: 100%; /* Ensure both containers go full width */
    /* Reset any flex properties that might affect width */
    flex: none;
  }

  .lowerpictures {
    display: block; /* Adjust display if necessary for alignment */
    text-align: center;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally in the cross axis */
    justify-content: center; 
  }

  .lowertext {
    display: block; /* Ensure text container also goes full width */
  }
  .omeni3{
    width:50%;
    height: auto;
    text-align: center;
  }
  

}

/* For smaller phones: */
@media (max-width: 550px) {

  .drugiprofil{
    width: 50%;
    height: auto;
    float: none;
    border-radius: 20px;
  }
  .skupina-slik{
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  .skupina-slik .pictures {
    order: 2;
    margin-bottom: 20px;
  }
  
  .skupina-slik h1 {
    order: 1;
  }
  
  .skupina-slik h5 {
    order: 3;
  }
  .omeni3{
    width:70%;
    height: auto;
    text-align: center;
    border-radius: 20px;
  }

}
  
@media (max-width: 480px) {
  .backbotgrid{
    display: none;
  }
}