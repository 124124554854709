.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 10%;
  background-color: #333;
  color: #fff;
  z-index: 9;
  font-family: 'Arial', sans-serif;
}

.footer-left {
  flex: 0 0 60%;
  text-align: center;
  margin-bottom: 20px;
}

.footer-right {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-bottom: 20px;
}

.footer-right h5 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.social-media-links {
  margin-bottom: 20px;
}

.social-media-links a {
  color: #fff;
  margin-right: 10px;
  transition: color 0.3s ease;
}

.social-media-links a:hover {
  color: #ccc;
}

.company-info {
  width: 100%;
  text-align: center;
}

.company-info p {
  margin: 0;
}

.logo-foot {
  width: 50%;
  margin-bottom: 20px;
}

/* Media Queries */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 5%;
  }

  .footer-left,
  .footer-right {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  .footer-right {
    align-items: center;
  }

  .logo-foot {
    width: 70%;
  }
  .social-media-links{
    display: block;
    text-align: center !important;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 2%;
  }

  .footer-left,
  .footer-right {
    flex: 0 0 100%;
  }

  .logo-foot {
    width: 90%;
  }


}
