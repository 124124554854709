.clanki-section {
    padding: 20px;
    padding: 1% 15%;
    text-align: center;
    background-image: linear-gradient(to bottom, rgb(220,171,112,0.63), white 40%);
  }
  
  .article-container {
    display: flex;
    flex-direction: column;
  }
  
  .article {
    display: flex;
    margin-bottom: 20px;
    position: relative;
    padding: 5%;
  }
  .article .article-image-container{
    
  }
  
  .article-image-container {
    position: relative; /* Set the container to relative positioning */
    width: 40%; /* Image takes up half of the row */
    height: fit-content;
    background-color: rgb(220,171,112,0.63);
  }
  
  .article-image {
    width: 100%; /* Ensure the image fills its container */
    height: auto; /* Maintain aspect ratio */
    position: relative;
    top: -20px;
    left: -20px
  }
  .image-left{
    margin-right: 5%;
  }
  .image-right{
    margin-left: 5%;
  }

  .article-desni{
    margin-left: 10%;
    text-align: left;
    width: 100%;
  }

  .article-levi{
    width: 90%;
    text-align: right;
  }


  .article-details {
    flex: 1; /* Text takes up the remaining space */
  }
  
  .article-details h2 {
    margin-top: 0;
  }
  
  .article-details p {
    margin-bottom: 0;
  }

  @media (max-width: 990px) {
    .article {
      flex-direction: column;
    }
  
    .article-image-container, .article-details {
      width: 100%; /* Ensure they fill the container */
      order: 1; /* Reset order to make sure images come first */
    }
  
    /* Specifically target the text container of the second article to appear below the image */
    .article:nth-child(2) .article-details {
      order: 2; /* Ensure the text appears after the image */
    }

    .article-desni{
      margin-left: 0;
      margin-top: 30px;
    }
    .image-right{
      margin-right: 5%;
      margin-left: 0;
    }
    .article-levi{
      width: 100%;
      text-align: left;
      margin-left: 0;
      margin-top: 30px;
    }

  }

  @media (min-width: 992px) and (max-width: 1199px) {

    .clanki-section {
      padding: 1% 13%;
    }
  }

  
  /* For tablets: */
  @media (min-width: 768px) and (max-width: 991px) {
    .clanki-section {
      padding: 1% 10%;
    }


}
  
  /* For phones: */
  @media (max-width: 767px) {
    .clanki-section {
      padding: 1% 7%;
    }

  }
  
  /* For smaller phones: */
  @media (max-width: 480px) {


  }