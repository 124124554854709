.yt-section {
    position: relative;
    background-image: linear-gradient(#DCAB70, white 65%);
    background-position: bottom left, bottom left;
    background-repeat: no-repeat;
    margin: 0;
    padding: 2% 15%;
    overflow: hidden; /* Hide overflow */
}

.container{
    max-width: 100%;
}

.hr-styledb {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, #DCAB70, #fff, #DCAB70);
    margin: 20px 0;
    position: relative;
}

.hr-styledb::after {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    height: 2px;
    background: #DCAB70;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
}

.hr-styledb:hover::after {
    transform: scaleX(1);
}

.yt-title{
    text-align: center;
    font-size: 4rem;
    padding-bottom: 3%;
    padding-top: 1%;
    text-shadow: 1px 1px 2px #fff;
}

.yt-video{
    text-align: center;
}

.video {
    border: 2px solid #DCAB70;
    box-shadow: -8px -8px 8px -5px #DCAB70; /* Shadow style */
    padding: 15px;
    margin-bottom: 20px;
    width: 100%;
    height: 400px;
}

.opis{
    padding-left: 4%;
}

.button{
    width: fit-content;
    background-color: #DCAB70;
    color: white;
    border-radius: 10px;
}

.button:hover{
    background-color: #B5894A;
    box-shadow: 0 0 5px 0 #DCAB70;
    color: white;
}


.triangle {
    width: 0;
    height: 0;
    border-left: 40vw solid transparent; /* Extend triangle to full viewport width */
    border-bottom: 100vh solid white; /* Adjust the height of the triangle and color */
    position: absolute;
    top: 0;
    right: 0; /* Move half of the triangle out of the page */
    z-index: -0; /* Move triangle behind content */
}

/*@media (max-width: 560px) {
    .triangle {
        display: none;
    }
}*/

@media  (max-width: 1499px) {


  }

  @media (max-width: 1199px) {

  }

  /* For desktops: */
  @media (min-width: 1066px) and (max-width: 1199px) {
    .yt-title{
        font-size: 4rem;
    }
    .yt-section {
        padding: 2% 13%;

    }
  }

  
  /* For tablets: */
  @media (min-width: 768px) and (max-width: 1065px) {
    .yt-title{
        font-size: 3.6rem;
    }
    .yt-section {
        padding: 2% 10%;

    }
    .triangle{
        display: none;
    }

}
  
  /* For phones: */
  @media (max-width: 767px) {

    .yt-title{
        font-size: 3.3rem;
    }
    .yt-section {
        padding: 2% 7%;

    }


  }
  
  /* For smaller phones: */
  @media (max-width: 480px) {
    .yt-title{
        font-size: 3rem;
    }

  }

    /* For smaller phones: */
    @media (max-width: 380px) {

    }