.navbar {
  position: relative;
  top: 0;
  z-index: 1000; /* Ensure it stays on top of other content */
}

.logo{
  margin-top: 5px;
  width:250px;
}

.navbar-add { /* or position: absolute; */
    background: #FBF5EE;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    padding: 10px 10%;
    /* Other navbar styles */
  }
  .no-border {
    border: 0 !important; /* Use !important to override default styles if necessary */
  }


  .navbar-brand {
    margin-right: 20px; /* Adjust the right margin as needed */
  }
  
/* Custom CSS */
.navbar-light .navbar-nav .nav-link {
    color: black; /* Set link color to white */
  }
  
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus {
    color: #dcab70 !important; /* Change link color on hover/focus */
  }

  .logo{
    color: white;
  }

  .navbar-light.home .navbar-nav .nav-link {
    color: white; /* Set link color to white */
  }
  .navbar-light.home .navbar-nav .nav-link:hover,
  .navbar-light.home .navbar-nav .nav-link:focus {
    color: #dcab70 !important; /* Change link color on hover/focus */
  }

  /* This will target the toggle button specifically */
.navbar .navbar-toggler {
  position: relative;
  border: none; /* Removes the border */
  box-shadow: none; /* Removes any box-shadow that might appear as a border */
  font-size: 1.5rem;
  z-index: 2000;
}

/* To target the FontAwesome icon inside the toggle button if necessary */
.navbar .navbar-toggler .fa-bars {
  border: none; /* Removes the border */
  box-shadow: none; /* Removes any box-shadow */
}
.navbar .navbar-toggler .fa-bars:focus {
  border: none; /* Removes the border */
  box-shadow: none; /* Removes any box-shadow */
}
.navbar .navbar-toggler:focus {
  outline: none !important; /* Removes the focus outline */
  border: none !important;
  box-shadow: none !important; 
}
.home .navbar .navbar-toggler {
  color:white;
}

.home{
  padding: 10px 10%;
}

@media (max-width: 400px) {
  .logo{
    margin-top: 5px;
    width:210px;
  }
}




/*.navbar-collapsed-content {
  position: absolute; /* Absolute position to take it out of normal document flow */
  /*/top: 100%;  Start right below the navbar */
  /*left: 0;
  right: 0;
  background: #FBF5EE;  Your desired background color */
  /*z-index: 1020;  Make sure it stacks on top of other content 
}
.home .navbar-collapsed-content .navbar-nav .nav-link{
  
}*/

@media (max-width: 991px) { /* This breakpoint should match Bootstrap's */
  .navbar-collapsed-content {
    position: absolute; /* Absolutely position the expanded navbar content */
    top: 0%; /* Set this to the height of your Navbar */
    left: 70%;
    right: 0;
    z-index: 1500; /* Above other content */
    padding: 20px;
    padding-top: 80px;
    text-align: center;
    background-color: #FBF5EE; /* The background color for the open navbar */
    /* Other styles for the expanded state */
  }

  .home .navbar-collapsed-content .navbar-nav .nav-link{
    color: black;
  }

}
@media (max-width: 500px) { 
  .navbar-collapsed-content {

    left: 60%;

    /* Other styles for the expanded state */
  }
}