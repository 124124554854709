.contact-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .contact-info, .contact-form {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  

  
  /* Responsive adjustments */
@media (max-width: 900px) {
    .contact-info, .contact-form {
      width: 100%; /* Makes each take full width, stacking them */
    }
  }

  .padd{
    padding: 2px 10px;
  }

  .icon{
    color: #dcab70;
  }