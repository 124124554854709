  .newsletter-field{
    border-width: 3px;
    border-color: #DCAB70;
    height: 45px;
    border-radius: 10px;
  }

.newsletter-button {
    position: relative;
    width: 100px;
    margin-left: -15px;
    z-index: 1;
    border-color: #B5894A;
    background-color: #DCAB70;
  }  

  .newsletter-button:hover {
    background-color: #B5894A;
    border-color: #DCAB70;
  }

  .newsletter-button:focus,
.newsletter-button:active {
    background-color: #B5894A !important;
    border-color: #DCAB70 !important;
  outline: none !important;
  box-shadow: none !important;
  transition: none; /* Disable transition on :active state */
}

.newsletter-field:focus,
.newsletter-field:active {
    border-color: #DCAB70 !important;
    outline: none !important;
  box-shadow: 0 0 5px 0 #DCAB70 !important;
}

.tekst{
    margin-bottom: 17px !important;
    color: white !important;
    letter-spacing: 1.5px;
}





  .arrow-icon {
    color: white; /* Set the color to white */
    font-size: 22px;
  }
  