.card-container {
    display: flex;
    justify-content: space-evenly; /* Adjust alignment as needed */
    flex-wrap: wrap; /* Allow cards to wrap to the next row */
  }
  
  .ponudba-section{
    text-align: center;
    padding: 3% 15% 1% 15%;
    background-image: linear-gradient(to top, rgb(220,171,112,0.63), white 40%);
  }

.obvestilo{
  position: relative;
  letter-spacing: 1.1px;
  margin-bottom:5%;
  text-align: center;
  z-index: 0;
}

.hr-styledb {
  border: 0;
  height: 2px;
  background-image: linear-gradient(to right, #DCAB70, #fff, #DCAB70);
  margin: 20px 0;
  position: relative;
}

.hr-styledb::after {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  height: 2px;
  background: #DCAB70;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.hr-styledb:hover::after {
  transform: scaleX(1);
}
