/* Login.css */
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Use the full height of the viewport */
    background-color: #f7f7f7; /* Light grey background */
}

.login-form {
    padding: 2rem;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px; /* Maximum width of the form */
}

.input-container {
    margin-bottom: 1rem;
}

.login-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.login-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.login-button {
    padding: 0.5rem;
    border: none;
    background-color: #333;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #555;
}

.error-message {
    color: red;
    margin-top: 1rem;
    text-align: center;
}
