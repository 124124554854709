/* Overall container */
.cms-blog-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Loading state styling */
  .loading-indicator {
    font-size: 1.25rem;
    text-align: center;
    padding: 50px;
  }
  
  /* Form styling */
  .edit-form .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    display: block;
    margin-bottom: .5rem;
    font-size: 1rem;
    color: #333;
  }
  
  .form-input, .form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #333;
  }
  
  .form-textarea {
    min-height: 100px;
  }
  
  /* Button styling */
  .btn {
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  
  .btn-add { background-color: #4CAF50; color: white; }
  .btn-remove { background-color: #f44336; color: white; }
  .btn-cancel { background-color: #9E9E9E; color: white; }
  .btn-save { background-color: #2196F3; color: white; }
  .btn-update { background-color: #FFC107; color: white; }
  .btn-delete { background-color: #f44336; color: white; }
  
  .btn:hover {
    filter: brightness(90%);
  }
  
  /* Blog details view */
  .blog-details-view {
    text-align: left;
  }
  
  .blog-title {
    font-size: 2rem;
    color: #333;
  }
  
  .blog-date {
    font-size: 1rem;
    color: #666;
  }
  
  .blog-image {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 4px;
  }
  
  .blog-section .section-subtitle {
    font-size: 1.5rem;
    color: #333;
    margin-top: 20px;
  }
  
  .section-paragraph {
    text-align: left;
    color: #666;
    line-height: 1.6;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .cms-blog-details-container {
      padding: 10px;
    }
  
    .edit-form .form-group, .blog-section {
      padding: 0 5px;
    }
  }
  