.blog-list-container {
    display: flex;
  }
  
  .most-recent {
    flex: 1;
  }

  .blog-item{
    margin-top: 3%;
  }
  
  .other-blogs {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px); /* Adjust 200px to accommodate other elements if needed */
    overflow-y: auto; /* Enable vertical scrolling */
    line-height: 2;
  }
  
  .other-blogs .blog-item { 
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .blog-item .image img  {
    /* Let the image take the necessary space */
    border: 3px solid black;
  }

    
  .other-blogs .blog-item .image  {
    width: 50%;
    margin: 0;
  }
  
  .other-blogs .blog-item .image img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  .other-blogs .blog-item .details {
    flex: 1; 
    padding: 10px;

  }
  .other-blogs .blog-item .details p {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
  .other-blogs .blog-item .details h3 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }


  .most-recent .blog-item img {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  /* width */
.other-blogs::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

/* Handle */
.other-blogs::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
}

/* Handle on hover */
.other-blogs::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blog-details{
  padding: 5% 15%;
}
.blog-body {
  display: flex;
  flex-direction: row;
}

.blog-navigation {
  width: 20%;
  float: left; /* Consider using flexbox properties instead of float for modern layouts */
  padding-top: 20px;
}

.blog-content {
  width: 80%;
  float: right; /* Consider using flexbox properties instead of float for modern layouts */
  padding: 20px;
}
.blog-info{
  width: 100%;
  text-align: center;
}

.blog-info img{
  width:100%;
}

.blog-info p{
  margin:3%;
}

.blog-info h1{
  margin:5%;
}

.small-circle {
  font-size: 9px; 
  color: #dcab70;
}
.hourglass{
  font-size: 12px;
}
.category{
  color: #dcab70;
  font-weight: bold;
}

.content-link a {
  color: black;
  scroll-behavior: smooth;
}

/* CSS file linked to your BlogDetails component */
.blog-smooth a {
  scroll-behavior: smooth;
}

.blog-navigation{
  position: relative;

}

.stick{
  position: sticky;
  top: 50px; /* Adjust this value if you have a header/navbar and you want the sticky element to appear below it */
  z-index: 1000;
}


@media (min-width: 992px) and (max-width: 1199px) {


}
@media (min-width: 992px) and (max-width: 1199px) {


}


/* For tablets: */
@media (min-width: 768px) and (max-width: 991px) {
  .other-blogs .blog-item {
    flex-direction: column;
     /* Adjusting margin for smaller screens */
  }
  
   
  .other-blogs .blog-item .details {
    width: 100%; /* Ensure full width for smaller screens */
    margin: 10px 0; /* Adjust margin for a vertical layout */
  }
  .other-blogs .blog-item .image{
    width: 100% !important;
  }

  .most-recent {
    flex: 3 1 !important; /* Does not grow, does not shrink, starts at 60% width */
}

.other-blogs {
    flex: 2 1 !important; /* Does not grow, does not shrink, starts at 40% width */
}

}

/* For phones: */
@media (max-width: 767px) {
  .other-blogs .blog-item {
    flex-direction: column;
    margin-left: 0; /* Adjusting margin for smaller screens */
  }
  
   
  .other-blogs .blog-item .details {
    width: 100%; /* Ensure full width for smaller screens */
  }

  .other-blogs .blog-item .image{
    width: 100% !important;
  }

  .most-recent {
    flex: 3 1 !important; /* Does not grow, does not shrink, starts at 60% width */
}

.other-blogs {
    flex: 2 1 !important; /* Does not grow, does not shrink, starts at 40% width */
}

}

/* For smaller phones: */
@media (max-width: 550px) {
  .blog-list-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    max-height: calc(100vh - 100px); /* Adjust as needed */
    overflow-y: auto; /* Enable vertical scrolling for the container */
}
  

.most-recent, .other-blogs {
    flex: 0 0 100% !important; /* Each section takes full width */
    width: 100%; /* Ensure full width */
}

.other-blogs .blog-item {
    flex-direction: column;
    margin-left: 0 !important;
}

.other-blogs .blog-item .details, .other-blogs .blog-item .image {
    width: 100%; /* Ensure full width for smaller screens */
}
.other-blogs {
  max-height: none !important; /* Adjust 200px to accommodate other elements if needed */
  overflow-y: visible; /* Enable vertical scrolling */

}

}

@media (max-width: 680px) {
  .blog-navigation{
    display: none;
  }
  .blog-content{
    width: 100% !important;
  }
}

.download-section{
  text-align: right;
}