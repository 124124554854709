.blog-list-container {
    display: flex;
  }
  
  .most-recent {
    flex: 1;
  }

  .blog-item{
    margin-top: 3%;
  }
  
  .other-blogs {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 200px); /* Adjust 200px to accommodate other elements if needed */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .other-blogs .blog-item { 
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .blog-item .image img  {
    /* Let the image take the necessary space */
    border: 3px solid black;
  }

    
  .other-blogs .blog-item .image  {
    /* Let the image take the necessary space */
    width: 50%;
    
  }
  
  .other-blogs .blog-item .image img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  .other-blogs .blog-item .details {
    flex: 1; 
    padding: 10px;

  }
  .other-blogs .blog-item .details p {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
  .other-blogs .blog-item .details h3 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }


  .most-recent .blog-item img {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  /* width */
.other-blogs::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

/* Handle */
.other-blogs::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
}

/* Handle on hover */
.other-blogs::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blog-details{
  padding: 5% 15%;
}
.blog-body {
  display: flex;
  flex-direction: row;
}

.blog-navigation {
  width: 20%;
  float: left; /* Consider using flexbox properties instead of float for modern layouts */
  padding-top: 20px;
}

.blog-content {
  width: 80%;
  float: right; /* Consider using flexbox properties instead of float for modern layouts */
  padding: 20px;
}
.blog-info{
  width: 100%;
  text-align: center;
}

.blog-info img{
  width:100%;
}

.blog-info p{
  margin:3%;
}

.blog-info h1{
  margin:5%;
}

.content-link a {
  color: black;
  scroll-behavior: smooth;
}


