.dejavnosti-section {
    margin: 3% 15%;
    text-align: center;
}

.orange-background {
    background-image:
        linear-gradient(to right, rgb(220,171,112,0.63), white 20%),
        /* Gradient from left to right */
        linear-gradient(to left, rgb(220,171,112,0.63), white 20%);
    /* Gradient from right to left */
    background-size: 50% 100%, 50% 100%;
    /* Adjust the background size to control the distribution */
    background-position: left, right;
    /* Set background position */
    background-repeat: no-repeat;
    padding: 20px;
    /* Adjust padding as needed */
    margin-top: 20px;
    /* Adjust margin as needed */
    border-radius: 30px;
}

.hr-styled {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, #fff, #DCAB70, #fff);
    margin: 20px 0;
    position: relative;
}

.hr-styled::after {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    height: 2px;
    background: #DCAB70;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
}

.hr-styled:hover::after {
    transform: scaleX(1);
}

.dejavtitle {
    margin-bottom: 2%;
    font-size: 4.3rem;
    text-shadow: 2px 2px 2px rgb(0,0,0,0.4);
    padding: 2% 0;
}

.subtitle{
    margin-bottom: 3%;
    font-size: 2.3rem;
}
.cardlist{
    margin-bottom: 2%;
}

.button{
    width: fit-content;
    background-color: #DCAB70;
    border: #DCAB70;
    color: white;
    border-radius: 10px;
}

.button:hover{
    background-color: #B5894A;
    box-shadow: 0 0 5px 0 #DCAB70;
    color: white;
}

  /* For desktops: */
  @media (min-width: 992px) and (max-width: 1199px) {
    .dejavtitle {
        font-size: 4rem;
    }

  }

  
  /* For tablets: */
  @media (min-width: 768px) and (max-width: 991px) {

    .dejavtitle {
        font-size: 3.6rem;
    }

}
  
  /* For phones: */
  @media (max-width: 767px) {


    .dejavtitle {
        font-size: 3.3rem;
    }

  }
  
  /* For smaller phones: */
  @media (max-width: 480px) {

    .dejavtitle {
        font-size: 3rem;
    }
    .subtitle{
        margin-bottom: 3%;
        font-size: 2rem;
    }


  }
