.landing-section {
    position: relative;
    height: 120vh; /* Set height to full viewport height */
    background-image: url('../../assets/images/landing-section.png'); /* Add your image here */
    background-size: cover; /* Cover the entire element with the background image */
    background-position: center top 40%; /* Center the background image */
    color: white; /* Text color */
    display: flex;
    justify-content: flex-start; /* Align items to the start (left) */
    align-items: center;
    padding: 0 20px; /* Add padding as needed */
  margin-top: -115px;
  }
  
  .landing-text {
    position: relative;
    margin-left: 13%;
    max-width: 40%; /* Limit the width of the text */
  }

  .heading {
    font-size: 4.2rem; /* Adjust the font size as needed clamp(14px, 2vw, 22px);*/
    line-height: 1.5; /* Adjust the line height as needed */
    font-weight: bold; /* Adjust the font weight as needed */
    position: relative; /* Ensure proper stacking order */
    z-index: 1; /* Ensure white text is on top */
  }

  .white-heading {
    position: relative;
    color: white;
    z-index: 1;
  }

  .orange-heading {
    font-family: "Dancing Script", cursive;
    color: #DCAB70;
    color: rgba(220, 171, 112,0.67);
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 0;
  }
  .orange-heading .ime-ozadje{
    position: relative;
    left: 50%;
  }

  .orange-heading .ime-ozadje br{
    display: none;
  }

  .landing-description{
    margin: 10% 0;
  }

  /* For large desktops: */
  @media (max-width: 1500px) {

  }
  
  @media  (max-width: 1499px) {
    .orange-heading .ime-ozadje{
      left: 0;
    }
  }

  @media (max-width: 1430px) {
    .orange-heading .ime-ozadje br{
      display: block;
    }
  }

  /* For desktops: */
  @media (min-width: 992px) and (max-width: 1199px) {
    .heading {
      font-size: 3.7rem; /* Adjust the font size as needed clamp(14px, 2vw, 22px);*/
    }
  }

  
  /* For tablets: */
  @media (min-width: 768px) and (max-width: 991px) {
    .heading {
      font-size: 3.2rem; /* Adjust the font size as needed clamp(14px, 2vw, 22px);*/
    }
  }
  
  /* For phones: */
  @media (max-width: 767px) {
    .heading {
      font-size: 2.7rem; /* Adjust the font size as needed clamp(14px, 2vw, 22px);*/
    }

  }
  
  /* For smaller phones: */
  @media (max-width: 480px) {
    .heading {
      font-size: 2.2rem; /* Adjust the font size as needed clamp(14px, 2vw, 22px);*/
    }
    .landing-section {
      background-position: calc(50% - 100px) 70%; /* Center the background image */
    }
  }

    /* For smaller phones: */
    @media (max-width: 380px) {
      .heading {
        font-size: 1.7rem; /* Adjust the font size as needed clamp(14px, 2vw, 22px);*/
      }
    }