.brown-field{
    background-color: #DCAB70;
    border-radius: 20px;
    width: 100%;
    padding: 10px 25px;
    margin-bottom: 10px;
}

.brown-field p{
    margin-bottom: 0;
}