.blog-form .form-container {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .blog-form .form-group {
    margin-bottom: 20px;
  }
  
  .blog-form .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
  }
  
  .blog-form .form-group input,
  .blog-form .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .blog-form .form-group textarea {
    height: 100px;
    resize: vertical;
  }
  
  .blog-form .form-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .blog-form .form-actions button {
    padding: 10px 15px;
    font-size: 14px;
    color: #fff; /* Keeps the text color white for contrast */
    background-color: #000; /* Changed to black */
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .blog-form .form-actions button:hover {
    background-color: #333;
  }

  .blog-form .form-actions button {
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    background-color: #000; /* Changed to black */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .blog-form .form-actions button:hover {
    background-color: #444; /* Slightly lighter black on hover */
  }
  
  /* Style for disabled button */
  .blog-form .form-actions button:disabled {
    background-color: #555; /* Grayish color to indicate disabled state */
    cursor: not-allowed; /* Change cursor to indicate action is not allowed */
  }

  .error {
    color: red;
    margin-top: 5px;
}