.App {
    font-family: "Raleway", sans-serif;
    line-height: 2.25rem;
}

.postavitev{
    margin: 3% 15%;
    padding: 0;
}

.dancingfont{
    font-family: "Dancing Script", cursive;
}


@media (min-width: 992px) and (max-width: 1199px) {

    .postavitev {
      margin: 3% 13%;
    }
  }

  
  /* For tablets: */
  @media (min-width: 768px) and (max-width: 991px) {
    .postavitev {
      margin: 3% 10%;
    }


}
  
  /* For phones: */
  @media (max-width: 767px) {
    .postavitev {
      margin: 3% 7%;
    }

  }
  
  /* For smaller phones: */
  @media (max-width: 480px) {


  }

  .button {
    background-color: #DCAB70; /* Button default background color */
    border-color: #DCAB70; /* Button default border color */
    color: white; /* Button text color, adjust as needed */
    transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover effect */

    border-radius: 10px;
  }
  
  .button:hover {
    background-color: #B5894A; /* Button hover background color */
    border-color: #B5894A; /* Button hover border color */
  }
  
  .button:focus,
  .button:active {
    background-color: #DCAB70 !important; /* Keep the hover background color even when focused/active */
    border-color: #DCAB70 !important; /* Keep the hover border color even when focused/active */
    outline: none !important; /* Remove focus outline */
    box-shadow: none !important; /* Remove focus shadow */
    transition: none; /* Disable transition on :focus and :active state */
    color: white !important;

  }