.cenik .card-container .ponudba-card .card-footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    background-color: transparent;
    border-top: none;
    padding: 5px;
  }

  .cenik .card-container .ponudba-card .cardtitle{
    color: #DCAB70;
    font-family: "Dancing Script", cursive;
    font-size: 1.8rem;

  }
  
  
  .cenik .card-container .ponudba-card .card-footer .price {
    align-self: flex-end; /* Align the price to the right */
    font-family: "Dancing Script", cursive;
  }
  
  .cenik .card-container .ponudba-card .card-footer .button {
    margin-top: 20px;
    width: 100%; /* Make the button take full width */
  }

  .cenik .card-container .ponudba-card  {
    position: relative;
    width: 300px;
    transition: transform 0.3s ease;
    z-index: 600;
  }

  .ponudba-section .card-container .ponudba-card  {
    width: 270px;
    transition: transform 0.3s ease;
  }
  .ponudba-section .card-container .ponudba-card .cardtitle  {
    display: flex;
    justify-content: center; /* Horizontally centers */
    align-items: center; /* Vertically centers */
  }

  .description-item {
      display: flex;
      justify-content: space-between;
  }
  
  .price-choice {
    margin-left: 10px; /* Optional: Adjust margin as needed */
    font-family: "Dancing Script", cursive;
}

.cenik {
  position: relative;
  background-color: #dcab70;
  padding: 3% 15%;
 z-index: 0;
 overflow: hidden;
}

.circle {
  position: absolute;
  border-radius: 50%;
}

.circle-1 {
  background-color: #DEB079; /* Example color for circle 1 */
  top: -150px;
  right: 300px;
  width: 90%;
  height: 140%;
z-index: -1;
overflow: hidden; 
}

.circle-2 {
  background-color: #DFB582; /* Example color for circle 2 */
  width: 60%;
  height: 100%;
  top: -130px;
  left: -130px;
  overflow: hidden; 
}

.ponudba-card:hover {
  transform: scale(1.3); /* Increase scale on hover */
  z-index: 2;
}

.priporocilo {
  position: relative;
  bottom: 0;
  background-color: #ffffff; /* Adjust as needed */
  padding: 10px;
  margin-top: 50px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
  z-index: 2;
  opacity: 0; /* Initially hide the element */
  transition: opacity 1s ease; 
}

.priporocilo.show {
  opacity: 1; /* Show the element when it has the 'show' class */

}