.spots-grid {
    display: grid;
    /* Adjust the 'auto-fill' to the number of columns you want */
    grid-template-columns: repeat(var(--columns), 10px); 
    /* Adjust 'auto-fill' to the number of rows you want */
    grid-template-rows: repeat(var(--rows), 10px);
    gap: 11px; /* Adjust the gap between the spots */
  }
  
  .spot {
    width: 10px; /* Adjust the size of the spots */
    height: 10px; /* Adjust the size of the spots */
    background-color: #DCAB70; /* Your chosen spot color */
    border-radius: 50%; /* This makes the divs circular */
  }